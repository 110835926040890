import { Icon } from '@volvo-cars/react-icons';
import {
  type DEPRECATED_IconType,
  getIconPropsFromDeprecatedIconType,
} from '@volvo-cars/react-icons/compat';

type FulfillmentInformationProps = {
  fulfillmentIcon: {
    alt: string;
    type: DEPRECATED_IconType;
  };
  fulfillmentInfo?: string | JSX.Element;
};

export function FulfillmentInformation({
  fulfillmentIcon,
  fulfillmentInfo,
}: FulfillmentInformationProps) {
  return (
    <div className="w-full mt-16">
      <div className="flex items-center">
        <Icon
          {...getIconPropsFromDeprecatedIconType(fulfillmentIcon.type)}
          alt={fulfillmentIcon.alt}
          className="rounded border-secondary bg-secondary mr-16 p-8"
        />
        {typeof fulfillmentInfo === 'string' ? (
          <p className="text-body">{fulfillmentInfo}</p>
        ) : (
          fulfillmentInfo
        )}
      </div>
    </div>
  );
}
